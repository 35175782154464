import { create } from 'zustand'

import { useTimer } from '@/store/timer'
import { client } from '@/api'
import { useUser } from './user'

export interface GameShare {
	title: string
	description: string
	image: string
}

export interface Game {
	id: string
	name: string
	description: string
	icon: string | React.ReactNode | null
	config: any
	userState: GameState | null
	publicationDate: string
}

export interface GameState {
	status: StatusApiEnum
	time: number
	state: any
}

enum StatusApiEnum {
	init = 'init',
	initialized = 'initialized',
	finished = 'finished',
	incompleted = 'incompleted',
}

export enum StatusEnum {
	paused = 'paused',
	playing = 'playing',
	howToPlay = 'howToPlay',
	ads = 'ads',

	init = 'init',
	countDown = 'countDown',
	initialized = 'initialized',
	finished = 'finished',
	incompleted = 'incompleted',
}

interface GameConfig {
	title: string
	icon: string
}

interface GameMeta {
	previousGame: string
	nextGame: string
	previousGameLabel: string
	nextGameLabel: string
	url: string
	config: GameConfig
	type: string
}

export interface GameDto {
	url: string
	type: string
	currentGame: Game | null
	meta: GameMeta | null
	currentGameState: GameState | null
	setCurrentGameState: (state: any) => void
	fetchGame: (id: string, type: string, url: string) => Promise<void>
	pause: () => void
	save: (status?: StatusEnum) => Promise<void>
	saveLocal: (data?: any) => void
	getLocal: (id: string, type: string) => any
	play: () => void
	finish: () => void
	reset: () => void
	status: StatusEnum
	setStatus: (status: StatusEnum) => void
	adsNextStatus: StatusEnum
	showAds: (nextStatus?: StatusEnum) => void
}

export const useCurrentGameStore = create<GameDto>((set: any, get: any) => ({
	url: '',
	type: '',
	currentGame: null,
	meta: null,
	currentGameState: null,
	setCurrentGameState: (state: any, finished: boolean = false) => {
		const time = useTimer.getState().time
		const currentGameState = {
			time,
			state,
			status: StatusApiEnum.initialized,
		}
		set({
			currentGameState,
		})
		if (finished) {
			get().finish()
		} else {
			get().save()
		}
	},
	status: StatusEnum.init,
	fetchGame: async (id: string, type: string, url: string) => {
		const { data: currentGame, meta }: any = await client.fetch([
			'game',
			type,
			id,
			{
				include: 'user_state',
			},
		])
		if (!currentGame) {
			return
		}

		set({
			url,
			type,
			status: StatusEnum.init,
			currentGame,
			meta,
		})

		const dataGameLocal = get().getLocal(currentGame.id, type)
		const dataGame = currentGame.userState || dataGameLocal
		let status: StatusEnum = StatusEnum.init
		if (dataGame) {
			let toSave = false
			if ((!currentGame.userState && dataGameLocal) || (dataGameLocal?.time && dataGameLocal.time > dataGame.time)) {
				dataGame.time = dataGameLocal.time
				dataGame.status = dataGameLocal.status
				dataGame.state = dataGameLocal.state
				toSave = true
			}

			switch (dataGame.status) {
				case StatusApiEnum.finished:
					status = StatusEnum.finished
					break

				case StatusApiEnum.incompleted:
					status = StatusEnum.incompleted
					break

				default:
					status = StatusEnum.init
					break
			}

			useTimer.getState().reset(dataGame.time)
			set({
				currentGameState: {
					state: dataGame.state,
					time: dataGame.time,
					status: dataGame.status,
				},
			})

			if (toSave) {
				get().save(dataGame.status)
			}
		} else {
			useTimer.getState().reset(0)

			set({
				currentGameState: {
					state: {},
					time: 0,
					status: StatusApiEnum.init,
				},
			})
		}

		get().showAds(status)

		if (dataGame?.status && [StatusApiEnum.finished, StatusApiEnum.incompleted].includes(dataGame?.status)) {
			get().finish()
		}
	},
	pause: () => {
		if (get().status !== StatusEnum.finished) {
			get().setStatus(StatusEnum.paused)
		}
	},
	getLocal: (id: string, type: string) => {
		const data = localStorage.getItem(`ara-game-${type}-${id}`)
		return data
			? JSON.parse(data)
			: {
					state: null,
					time: 0,
					status: StatusApiEnum.init,
			  }
	},
	saveLocal: (data: any = get().currentGameState.state) => {
		const { id = undefined } = get().currentGame || {}
		const { type = undefined } = get().meta || {}

		if (!id || !type) return

		localStorage.setItem(`ara-game-${type}-${id}`, JSON.stringify(data))
	},
	save: async (_status: StatusEnum = StatusEnum.initialized) => {
		const time = useTimer.getState().time
		const id = get().currentGame?.id
		const type = get().meta?.type
		const state = get().currentGameState.state

		let status: StatusApiEnum = StatusApiEnum.initialized
		if ([StatusEnum.finished, StatusEnum.incompleted, StatusEnum.init].includes(_status)) {
			status = _status as unknown as StatusApiEnum
		}

		const userState = useUser.getState().logged

		if (id && type && userState) {
			await client.mutate(['game', type, 'state', id], {
				state,
				time,
				status,
			})
		}

		get().saveLocal({
			state,
			time,
			status,
		})
	},
	play: () => {
		if (get().status === StatusEnum.init) {
			get().setStatus(StatusEnum.countDown)
		} else if (get().status !== StatusEnum.finished) {
			get().setStatus(StatusEnum.playing)
		}
	},
	finish: () => {
		const time = useTimer.getState().time
		const status = StatusEnum.finished
		// get().setStatus(status)
		get().save(status)
		get().saveLocal({
			state: get().currentGameState.state,
			time,
			status,
		})
		get().showAds(StatusEnum.finished)
	},
	setStatus: (status: StatusEnum) => {
		if (get().currentGame?.status === StatusEnum.finished) {
			get().finish()
			return
		}

		switch (status) {
			case StatusEnum.playing:
				useTimer.getState().start()
				set({ status: StatusEnum.playing })
				break

			case StatusEnum.paused:
				useTimer.getState().stop()
				set({ status: StatusEnum.paused })
				break

			case StatusEnum.init:
				useTimer.getState().reset()
				set({ status: StatusEnum.init })
				break

			case StatusEnum.finished:
				useTimer.getState().stop()
				set({ status: StatusEnum.finished })
				break

			case StatusEnum.countDown:
				set({ status: StatusEnum.countDown })
				break

			default:
				get().pause()
				set({ status })
				break
		}
	},
	adsNextStatus: StatusEnum.playing,
	showAds: (nextStatus: StatusEnum = StatusEnum.playing) => {
		set({ adsNextStatus: nextStatus })
		// get().setStatus(StatusEnum.ads)
		get().setStatus(StatusEnum.init)
	},
	reset: () => {
		set({
			currentGame: null,
			meta: null,
			currentGameState: null,
			status: StatusEnum.init,
		})
	},
}))
