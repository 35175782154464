var userData = null

export default function getUserData() {
	const nxSessionUserCookie = getCookie('nx-session-user')
	userData = cookieValueToJson(nxSessionUserCookie)
	return userData
}

function getCookie(name: any) {
	const cookies = document.cookie.split(';')
	for (const cookie of cookies) {
		const [cookieName, cookieValue] = cookie.trim().split('=')
		if (cookieName === name) {
			return decodeURIComponent(cookieValue)
		}
	}
	return null // La cookie no existeix
}

function cookieValueToJson(cookieValue: any) {
	if (cookieValue) {
		try {
			const nxSessionUserData = JSON.parse(cookieValue)
			return nxSessionUserData
		} catch (error) {
			console.error("No s'ha pogut descodificar el JSON de la cookie:", error)
			return null
		}
	} else {
		return null
	}
}
