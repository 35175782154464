import classNames from 'classnames'
import { useEffect } from 'react'
import getUserData from './nxUserData'

type Props = {
	children?: React.ReactNode
	as?: React.ElementType
	className?: string
}

function Container({ children, as: Component = 'div', className = '' }: Props) {
	//add script ssmas per mostrar publi
	useEffect(() => {
		const userData = getUserData()
		if ((userData && userData.subscriber === false) || !userData) {
			console.log('Activate SSMAS')
			const script = document.createElement('script')
			script.src = 'https://ssm.codes/smart-tag/jocsaracat.js'
			script.async = true
			document.body.appendChild(script)

			return () => {
				document.body.removeChild(script)
			}
		} else {
			console.log('No SSMAS, user is subscriber')
		}
	}, [])

	return <Component className={classNames('max-w-[972px] mx-auto px-3', className)}>{children}</Component>
}

export default Container
