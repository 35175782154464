import { useEffect, useState } from 'react'
import { TfiClose } from 'react-icons/tfi'
import { AiFillFacebook, AiFillMail, AiOutlineTwitter, AiOutlineWhatsApp } from 'react-icons/ai'
import { useLocation } from 'react-router'
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { useTranslation } from 'react-i18next'

import Modal from '@/components/modal'
import Link from '@/components/Link'
import Button from '@/components/button'
import { StatusEnum, useCurrentGameStore } from '@/store/game'
import { DateTime } from 'luxon'
import { useUser } from '@/store/user'
import { useTimer } from '@/store/timer'

type HowPlayModalProps = {
	children: React.ReactNode
}

export function HowPlayModal({ children }: HowPlayModalProps) {
	const { t } = useTranslation()
	const { status, play } = useCurrentGameStore()
	return (
		<Modal onClose={play} show={status === StatusEnum.howToPlay}>
			<div
				className='
        p-5 sm:p-10 border-b border-disabled-border flex justify-between items-center relative
        after:bg-primary after:w-20 after:h-[5px] after:absolute after:-bottom-[5px] after:left-10
        '
			>
				<h2 className='text-2xl font-semibold sm:text-4xl text-primary'>{t('games.howToPlay')}</h2>
				<Link as='div' className='text-3xl cursor-pointer text-disabled' onClick={play}>
					<TfiClose />
				</Link>
			</div>
			<div className='z-20 p-10'>{children}</div>
		</Modal>
	)
}

type InitGameModalProps = {
	children: React.ReactNode
}

export function InitGameModal({ children }: InitGameModalProps) {
	const { status, play, currentGame, meta } = useCurrentGameStore()
	const { t } = useTranslation()
	return (
		<Modal onClose={play} show={StatusEnum.init === status} className='!max-w-lg'>
			<div
				className='
        px-5 pt-5 pb-2 md:px-10 md:pt-10 md:pb-5 border-b flex justify-between items-center relative bg-primary-light
        after:bg-primary after:w-20 after:h-[5px] after:absolute after:-bottom-[5px] after:left-1/2 after:-translate-x-1/2 transform
      '
			>
				<div className='flex flex-col items-center justify-center w-full space-y-3'>
					<img src={meta?.config?.icon} alt='game icon' />
					<h2 className='text-2xl font-semibold text-center text-black sm:text-4xl'>{meta?.config?.title}</h2>
				</div>
			</div>
			<div className='z-20 px-4 py-5 max-sm:text-center sm:px-20 sm:py-10'>{children}</div>
			<div className='z-20 flex items-center justify-center pb-8 space-x-2 sm:space-x-5'>
				<Button as={Link} to='/' secondary>
					{t('games.back')}
				</Button>
				<Button onClick={play}>{t('games.start')}</Button>
			</div>
		</Modal>
	)
}

export function PauseGameModal() {
	const { status, play, currentGameState, meta } = useCurrentGameStore()
	const { t } = useTranslation()
	return (
		<Modal onClose={play} show={StatusEnum.paused === status} className='!max-w-lg'>
			<div
				className='
        px-5 pt-5 pb-2 md:px-10 md:pt-10 md:pb-5 border-b flex justify-between items-center relative bg-primary-light
        after:bg-primary after:w-20 after:h-[5px] after:absolute after:-bottom-[5px] after:left-1/2 after:-translate-x-1/2 transform
      '
			>
				<div className='flex flex-col items-center justify-center w-full space-y-3'>
					<img src={meta?.config?.icon} alt='game icon' />
					<h2 className='text-2xl font-semibold text-center text-black sm:text-4xl'>{meta?.config?.title}</h2>
				</div>
			</div>
			<div className='z-20 px-10 py-5 sm:px-20 sm:py-10 prose-base'>
				<div className='mb-5 text-2xl font-medium text-center text-primary'>{t('games.paused')}</div>
				<div className='text-sm text-center text-disabled'>{t('games.time.spent')}</div>
				<div className='font-bold text-center sm:text-5xl text-primary'>
					{DateTime.fromSeconds(useTimer.getState().time || 0).toFormat('mm’ss’’')}
				</div>
			</div>
			<div className='z-20 flex items-center justify-center pb-10 space-x-5'>
				<Button onClick={play}>{t('games.resume')}</Button>
			</div>
		</Modal>
	)
}

export function CountDownModal() {
	const { status, play, meta } = useCurrentGameStore()
	const [timeDown, setTimeDown] = useState(3)
	const { t } = useTranslation()

	useEffect(() => {
		if (status === StatusEnum.countDown) {
			const interval = setInterval(() => {
				setTimeDown((prev) => prev - 1)
			}, 1000)
			return () => clearInterval(interval)
		} else {
			setTimeDown(3)
		}
	}, [status])

	useEffect(() => {
		if (timeDown === 0) {
			play()
		}
	}, [timeDown])

	return (
		<Modal onClose={play} show={StatusEnum.countDown === status} className='!max-w-lg'>
			<div
				className='
        px-5 pt-5 pb-2 md:px-10 md:pt-10 md:pb-5 border-b flex justify-between items-center relative bg-primary-light
        after:bg-primary after:w-20 after:h-[5px] after:absolute after:-bottom-[5px] after:left-1/2 after:-translate-x-1/2 transform
      '
			>
				<div className='flex flex-col items-center justify-center w-full space-y-3'>
					<img src={meta?.config?.icon} alt='game icon' />
					<h2 className='text-2xl font-semibold text-center text-black sm:text-4xl'>{meta?.config?.title}</h2>
				</div>
			</div>
			<div className='z-20 px-10 py-5 sm:px-20 sm:py-10 prose-base'>
				<div
					className='text-2xl font-medium text-center text-primary'
					dangerouslySetInnerHTML={{
						__html: t('games.readyMessage'),
					}}
				/>
				<div className='my-10 text-5xl font-medium text-center text-primary'>{timeDown}’’</div>
			</div>
		</Modal>
	)
}

type FinishGameModalProps = {
	children?: React.ReactNode
}

export function FinishGameModal({ children }: FinishGameModalProps) {
	const { status, currentGameState } = useCurrentGameStore()
	const { logged } = useUser()
	const { pathname } = useLocation()
	const { t } = useTranslation()

	const url = `${window.location.origin}/${pathname?.split('/')?.at(1)}`

	return (
		<Modal onClose={() => {}} show={status === StatusEnum.finished} className='!max-w-lg space-y-8'>
			<div
				className='
        px-4 py-8 border-b
        after:bg-primary after:w-20 after:h-[5px] after:absolute after:-bottom-[5px] after:left-1/2 after:-translate-x-1/2 transform
      '
			>
				<h2 className='text-2xl font-semibold text-center sm:text-4xl text-primary'>{t('games.finished')}</h2>
			</div>
			<div className='z-20 flex flex-col items-center px-4 space-y-2 text-center sm:space-y-4 sm:px-20'>
				<div className='text-sm text-disabled'>{t('games.time.short')}</div>
				<div className='sm:text-5xl text-3xl font-bold text-primary !mb-5'>
					{DateTime.fromSeconds(currentGameState?.time || 0).toFormat('mm’ss’’')}
				</div>
				{children}
				{!logged && (
					<Button
						as={Link}
						to={`https://www.ara.cat/usuari/login?backUrl=${encodeURIComponent(
							import.meta.env.VITE_BASENAME + pathname
						)}`}
					>
						{t('games.save')}
					</Button>
				)}
			</div>
			<div className='flex items-center justify-between p-4 text-white sm:p-8 bg-primary'>
				<h3 className='text-xl'>{t('games.share')}</h3>
				<FacebookShareButton quote='Juga a l’Ara i descobreix com de català ets' url={url}>
					<AiFillFacebook className='text-4xl' />
				</FacebookShareButton>
				<TwitterShareButton title='Juga a l’Ara i descobreix com de català ets' url={url}>
					<AiOutlineTwitter className='text-4xl' />
				</TwitterShareButton>
				<WhatsappShareButton title='Juga a l’Ara i descobreix com de català ets' url={url}>
					<AiOutlineWhatsApp className='text-4xl' />
				</WhatsappShareButton>
				<EmailShareButton
					subject='Juga a l’Ara i descobreix com de català ets'
					body='Juga a l’Ara i descobreix com de català ets'
					url={url}
				>
					<AiFillMail className='text-4xl' />
				</EmailShareButton>
			</div>
		</Modal>
	)
}

// const useDfpSlot = (id: string) => {
// 	const path = '21675759561/ara2020/Content1'
// 	const size = [300, 600]

// 	useEffect(() => {
// 		const googletag = (window as any).googletag || {}
// 		googletag.cmd = googletag.cmd || []
// 		googletag.cmd.push(function () {
// 			googletag?.defineSlot(path, size, id)?.addService(googletag?.pubads())
// 			googletag?.pubads()?.enableSingleRequest()
// 			googletag?.enableServices()
// 			googletag?.display(id)
// 		})

// 		return () => {
// 			googletag?.cmd?.push(function () {
// 				googletag?.destroySlots()
// 			})
// 		}
// 	}, [id])
// }

export function AdsModal() {
	// const { status, setStatus, adsNextStatus } = useCurrentGameStore()
	// const [ableToClose, setAbleToClose] = useState(false)
	// const [init, setInit] = useState(false)
	// const closeAds = () => {
	//   if (ableToClose) {
	//     setStatus(adsNextStatus)
	//   }
	// }

	// const [randomId, setRandomId] = useState<string>(`initAds-${Math.random().toString(36).substring(2, 7)}`)

	// useDfpSlot(randomId)

	// useEffect(() => {
	//   setInit(true)
	//   setRandomId(`initAds-${Math.random().toString(36).substring(2, 7)}`)
	//   if (status === StatusEnum.ads) {
	//     setTimeout(() => {
	//       setAbleToClose(true)
	//     }, 3000)
	//   } else {
	//     setAbleToClose(false)
	//     setInit(false)
	//   }
	// }, [status])

	// if (status !== StatusEnum.ads) {
	//   return null
	// }

	return null

	// return (
	//   <Modal onClose={closeAds} show={status === StatusEnum.ads} className='!max-w-min rounded-none'>
	//     <div className='relative flex items-center justify-between'>
	//       <div
	//         id={randomId}
	//         className='w-full h-full'
	//       />
	//       {
	//         ableToClose &&
	//         <Link as='div' className='absolute p-2 text-xl rounded-full cursor-pointer text-disabled bg-disabled-light right-2 top-2' onClick={() => setStatus(adsNextStatus)}>
	//           <TfiClose />
	//         </Link>
	//       }
	//     </div>
	//     <div
	//       className='absolute bottom-0 left-0 z-30 w-0 h-2 transform bg-primary'
	//       style={{
	//         transition: 'width 3s linear',
	//         width: init ? '100%' : '0%',
	//       }}
	//     />
	//   </Modal>
	// )
}
